.email-login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-login-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 95%;
  margin: 40px 10px 100px 10px;
}

.email-sent-message {
  text-align: center;
  width: 95%;
  margin: 40px 10px 100px 10px;

  .email {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.25);
  }
}
