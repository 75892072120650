
@font-face {
  font-family: 'Modesto Text';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(./modesto-text-medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Modesto Text';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url(./modesto-text-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Modesto Condensed';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(./modesto-condensed-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(./poppins-400.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url(./poppins-700.woff2) format('woff2');
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

body {
  font-family: 'Modesto Text';
  font-style: normal;
  font-size: 16px;
  line-height: 110%;
  background-color: #f2f2f2;
  color: #282828;
}

p {
  color: #7B7B7B
}

h2 {
  font-family: 'Modesto Text';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 95%;
}

h4 {
  font-family: 'Modesto Text';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
