.download-header {
  margin: 0 20px 20px 20px;
}

.qr-code {
 width: 74%;
}

.download-box {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
}

.download-image {
  width: 100%;
}