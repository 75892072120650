.share-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 420px;
}

.share-link-logo {
  margin-top: 40px;
  width: 110px;
}

.share-link-inner {
  margin: 40px 10px 100px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.share-link-loading {
  margin: 50px auto 0 auto;

  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.share-link-header {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.share-link-description {
  margin-top: 16px;
  margin-bottom: 0px;
}

.social-share-box {
  margin-top: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #FFF;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.share-link-error {
  margin: 0 20px 0 20px;
}

.retry-button {
  margin-top: 20px;
  padding: 12px 16px;
  text-decoration: none;
  background: #d4d4d4;
  color: #F2F2F2;
  border: none;
  border-radius: 4px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  text-transform: uppercase;
}

.url-header {
  margin-bottom: 5px;
}

.url-copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 16px;

  background-color: transparent;
  border-radius: 4px;
  border: none;
  background-color: #F2F2F2;

  color: #282828;
  text-decoration: none;
  font-style: normal;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 1px;
}

.share-link-join {
  margin-top: 0px;
  margin-bottom: 0px;
}

.login-button {
  height: 40px;
  width: 90%;

  background-color: #FFF;
  color: #000;

  display: flex;
  align-items: center;

  font-family: 'Modesto Text';
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;

  border: 1px solid #d4d4d4;
  border-radius: 3px;
}

.login-button:hover{
  background: #F8F8F8;
}

.login-text {
  margin: auto;
}